/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-09-27 19:38:24
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:41:11
 */
import Vue from 'vue'
const Bus = new Vue()
export default Bus
